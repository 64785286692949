<mat-toolbar class="sticky-toolbar mat-elevation-z2" color="primary">
    <img class="dbbp-logo" src="assets/images/db-logo.svg" height="30" width="auto"
        alt="Logo" />&nbsp;<span>BahnPark Portal</span>
    <span class="header-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Account" *ngIf="user">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item aria-label="Benutzer" (click)="openUserDialog()">
            <mat-icon>person</mat-icon> {{user.username}}
        </button>
        <logout-button></logout-button>
    </mat-menu>
</mat-toolbar>

