import { Pipe, PipeTransform } from '@angular/core';
import { Cards } from './cards.interface';

@Pipe({
    name: 'showCardsByRole'
})
export class ShowCardsByRolePipe implements PipeTransform {

    auth_level: number = 0;

    transform(cards: any, role: any): Cards[] {

        switch (role) {
            case 'USER':
                this.auth_level = 0;
                break;
            case 'MANAGER':
                this.auth_level = 1;
                break;
            case 'ADMIN':
                this.auth_level = 2;
                break;
            default:
                this.auth_level = 0;
        }

        const filteredCards = cards.map((card: Cards) => {
            if (card.visibility === 'USER') card.visibility = 0;
            if (card.visibility === 'MANAGER') card.visibility = 1;
            if (card.visibility === 'ADMIN') card.visibility = 2;
            else card.visibility === 2;
            return card;
        });

        return filteredCards.filter((item: Cards) => item.visibility <= this.auth_level);

    }

}

