import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Store } from '@ngxs/store';

import { ShowCardsByRolePipe } from './show-cards-by-role.pipe';
import { AuthService } from '../core/services/auth.service';
import { CardsService } from './cards.service';
import { Cards } from './cards.interface';
import { SharedService } from '@core/services/shared.service';
import { MatSliderChange } from '@angular/material/slider';
import { ChangeGridSize } from '@core/states/grid-size.action';
import { GridSizeState } from '@core/states/grid-size.state';
import { User } from '@core/layout/header/user-dialog/user.interface';

/**
 * @title Home Component
 * 
 */
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    gridColumns: number = this.store.selectSnapshot(GridSizeState.currentGridSize);

    gridClass: string = `grid grid-cols-${this.gridColumns}`; 

    cards: Cards[];
    user: User;

    callToAction: boolean = true;

    searchTerm: string = '';

    constructor(
        private showCardsByRole: ShowCardsByRolePipe,
        private authService: AuthService,
        private sharedService: SharedService,
        private cardsService: CardsService,
        private store: Store
    ) { }

    ngOnInit(): void {

        forkJoin([
            this.cardsService.getJsonData(),
            this.authService.validate()
        ]).subscribe((results: any) => {
            console.log(results)
            // Fallback user role is "USER"
            let userRole = (typeof results[1].body === 'undefined') || (results[1].body === null) ? 'USER' : results[1].body.role;
            // Cards pipe TODO: do better job, a pipe is not the best solution here. Do service.
            this.cards = this.showCardsByRole.transform(results[0], userRole);
            // Get user object from request
            this.user = results[1].body;
            // Set current user state
            this.sharedService.setCurrentUser(results[1].body)
        });

    }

    get filteredCards() {
        return this.cards.filter(card => card.title.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }

    openLink(link: string, target: string): void {
        window.open(link, target);
    }

    onSliderChange(event: MatSliderChange): number {
        const gridsize =  event.value ? event.value : this.gridColumns;
        this.store.dispatch(new ChangeGridSize({value: gridsize}));
        this.updateGridClass(gridsize);
        return gridsize
    }

    updateGridClass(gridsize: number) {
        // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
        const gridClasses: any = {
          1: 'grid-cols-1',
          2: 'grid-cols-2',
          3: 'grid-cols-3',
          4: 'grid-cols-4',
          5: 'grid-cols-5',
          6: 'grid-cols-6',
          7: 'grid-cols-7',
          8: 'grid-cols-8',
          9: 'grid-cols-9',
          10: 'grid-cols-10',
          11: 'grid-cols-11',
          12: 'grid-cols-12',
        };
        this.gridClass = `grid ${gridClasses[gridsize]}`;
    }

}