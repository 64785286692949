import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
{
    path: '',
    component: LoginComponent
},
{
    path: '**',
    component: HomeComponent,
    canActivate: [AuthGuard]
},
{
    path: 'login',
    component: LoginComponent
},
{
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
