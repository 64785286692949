import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ChangeGridSize, GridSizeStateModel } from './grid-size.action';


@State<GridSizeStateModel>({
    name: 'gridsize',
    defaults: {
        value: 4
    }
})
@Injectable()
export class GridSizeState {

    @Selector()
    static currentGridSize(state: GridSizeStateModel): number {
        return state.value
    }
    
    constructor() { }

    @Action(ChangeGridSize)
    change(ctx: StateContext<GridSizeState>, action: ChangeGridSize) {
        ctx.patchState(action.newValue)
    }

}