import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../core/services/auth.service';

import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * @title Login component
 * 
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public userInputValue:string = '';
    public passwordInputValue:string = '';
    public hideVisibility:boolean = true;

    constructor(
        private auth: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {

        this.auth.validate(false).subscribe(
            (data: any) => {
                this.auth.setLoggedIn(true)
                this.router.navigate(['home'])
            },
            (err: any) => {
                this.auth.setLoggedIn(false)
            }
        )
    }

    login() {

        this.snackBar.open('Anmelden...', '', { duration: 2000, panelClass: 'info-snack' });

        this.auth.authorize(this.userInputValue, this.passwordInputValue).subscribe(
            (data: any) => {
                
                this.snackBar.open('Authentifizierung erfolgreich. Willkommen!', '', { duration: 2000, panelClass: 'success-snack' });
                this.auth.setLoggedIn(true)
                this.router.navigate(['home'])
    
            },
            (err: any) => {
                
                this.auth.setLoggedIn(false)

            }
        )

    }

}