import { Component, OnInit } from '@angular/core';
import { SharedService } from '@core/services/shared.service';

@Component({
    selector: 'user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

    user: any

    constructor(
        private shared: SharedService
    ) { }

    ngOnInit(): void {

        this.shared.currentUser.subscribe(data => this.user = data)

    }

}
