import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cards } from './cards.interface';

@Injectable({
	providedIn: 'root'
})
export class CardsService {

	constructor(
		private http: HttpClient
	) {}

	getJsonData() {
		return this.http.get<Cards[]>(`/assets/json/cards.json`);
	}

}
