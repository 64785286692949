<mat-card class="mat-elevation-z0" *ngIf="callToAction">
    <div class="flex flex-col justify-between items-center p-4 !mt-4">
        <h2>Hallo, {{ user.firstname }} {{ user.lastname }}!</h2>
        <p>Alle aufgelisteten Anwendungen der DB BahnPark sind hier schnell, bequem und übersichtlich erreichbar.</p>
        <img class="w-[640px]" src="https://hilfe.dbbahnpark.info/app/uploads/2023/11/220422_Homepage_Kapitel_Linie_bunt_Mobilitaetsmittel.png" />
    </div>
</mat-card>

<div *ngIf="cards" class="flex flex-row justify-between items-center p-4 sticky top-[64px] z-10 bg-[whitesmoke]">
    <mat-form-field class="!mb-[-1.25em] w-1/4" appearance="outline">
        <mat-label>
            Filter
        </mat-label>
        <input matInput type="text" [(ngModel)]="searchTerm">
        <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Löschen" (click)="searchTerm=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div class="flex flex-row justify-end items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-3x3-gap" viewBox="0 0 16 16">
            <path d="M4 2v2H2V2zm1 12v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m5 10v-2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1M9 2v2H7V2zm5 0v2h-2V2zM4 7v2H2V7zm5 0v2H7V7zm5 0h-2v2h2zM4 12v2H2v-2zm5 0v2H7v-2zm5 0v2h-2v-2zM12 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z"/>
        </svg>
        <mat-slider class="max-w-[200px]" color="primary" [max]="6" [min]="1" [(ngModel)]="gridColumns" (input)="onSliderChange($event)"></mat-slider>
        {{gridColumns}}/6
    </div>
</div>

<div class="gap-4 px-4 mb-4 sticky top-[126px]" [ngClass]="gridClass">
    <mat-card class="mat-elevation-z1 !flex flex-col justify-between" *ngFor="let card of filteredCards">
        <mat-card-header>
            <mat-card-title>{{card.title}}</mat-card-title>
            <mat-card-subtitle>{{card.subtitle}}</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image src="{{card.imageUrl}}" alt="{{card.imageAlt}}" (click)="openLink(card.buttonLink, '_blank')">
        <mat-card-content (click)="openLink(card.buttonLink, '_blank')">
            <p>{{card.content}}</p>
        </mat-card-content>
        <mat-card-actions class="!flex flex-row justify-between items-bottom">
            <a mat-button href="{{card.buttonLink}}" target="_blank">{{card.buttonName ? card.buttonName : 'APP &Ouml;FFNEN'}}</a>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Weitere Optionen" *ngIf="card.moreButtons">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <a mat-menu-item *ngFor="let button of card.moreButtons" href="{{button.link}}" target="_blank">
                    <mat-icon>{{button.icon ? button.icon : 'open_in_new'}}</mat-icon>
                    <span>{{button.name}}</span>
                </a>
            </mat-menu>
        </mat-card-actions>
    </mat-card>
</div>
