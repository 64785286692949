import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

    snackOptions:any =  { duration: 100000, panelClass: 'error-snack' };

    constructor(
        private snackBar: MatSnackBar
    ) {}
    
    intercept(
        request: HttpRequest<any>, 
        next: HttpHandler    
    ): Observable<HttpEvent<any>> {

        // https://github.com/angular/angular/issues/18155#issuecomment-326136484
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            return next.handle(request.clone({ headers }));
        }

        return next.handle(request).pipe(

            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 0 && err.error instanceof ProgressEvent) {
                        // client side
                        try {
                            console.error(`${err.name} ${err.statusText}`);
                            console.error(err.message);
                            this.snackBar.open(err.statusText, 'Schließen', this.snackOptions);
                        } catch (e) {
                            console.error('A unknown error has occured. Please try again later.');
                            this.snackBar.open('Ein unbekannter Fehler ist aufgetreten. Bitten probieren Sie es später noch einmal.', 'Schließen', this.snackOptions);
                        }
                    } else {
                        // backend side
                        try {
                            if ( typeof err.error.message !== 'undefined' ) {
                                console.error(`${err.error.error} ${err.status}`);
                                console.error(`Backend message: ${err.error.message}`);
                                this.snackBar.open(err.error.message, 'Schließen', this.snackOptions);
                            } else {
                                console.error('A unknown error has occured. Please try again later.');
                                this.snackBar.open('Ein unbekannter Fehler ist aufgetreten. Bitten probieren Sie es später noch einmal.', 'Schließen', this.snackOptions);
    
                            }
                        } catch (e) {
                            console.error('A unknown error has occured. Please try again later.');
                            this.snackBar.open('Ein unbekannter Fehler ist aufgetreten. Bitten probieren Sie es später noch einmal.', 'Schließen', this.snackOptions);
                        }
                    }
                }
                return throwError(err); // return of(err); // 
            })

        );
    }
}