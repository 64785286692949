import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { SharedService } from '@core/services/shared.service';
import { Observable, of } from 'rxjs';
import { UserDialogComponent } from './user-dialog/user-dialog.component'
import { User } from './user-dialog/user.interface';

/**
 * @title App header and navigation
 */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    user: any;
    gridColumns: number = 3;

    constructor(
        public dialog: MatDialog,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.sharedService.currentUser.subscribe(data => this.user = data)
    }

    openUserDialog() {
        this.dialog.open(UserDialogComponent);
    }

}
